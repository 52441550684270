// 
// user.js
// Use this to write your custom JS
//
(function (s, e, n, d, er) {
  s['Sender'] = er;
  s[er] = s[er] || function () {
    (s[er].q = s[er].q || []).push(arguments)
  }, s[er].l = 1 * new Date();
  var a = e.createElement(n),
      m = e.getElementsByTagName(n)[0];
  a.async = 1;
  a.src = d;
  m.parentNode.insertBefore(a, m)
})(window, document, 'script', 'https://cdn.sender.net/accounts_resources/universal.js', 'sender');
sender('4fc669e3982780')